import React, { useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Barcode from "react-barcode";
import SignatureCanvas from 'react-signature-canvas'

import { useFormFields } from './Field';
import api, { postFile, getResult } from './api';
import Constants from '../constants';
import LoaderButton from './LoaderButton';
import usb from './usb';
import serial from './port';

function getLocal(type) {
    const savedState = sessionStorage.getItem(type);
    let state = {};
    try {
        if (savedState)
            state = JSON.parse(savedState) || {};
    }
    catch{
        console.log('failed to parse JSON', type)
    }
    return state;
}
function setLocal(type, state) {
    const savedState = getLocal(type);
    return sessionStorage.setItem(type, JSON.stringify({ ...savedState, ...state }));
}
function getLocalPatient() {
    return getLocal("Patient");
}
function getLocalInsured() {
    return getLocal("Insured");
}

// validators
function isValidEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

function isValidAptDate(x) {
    const dateParts = parseISODateParts(x);
    if (!dateParts) return false;
    const [year, month, date] = dateParts;
    console.log(year, month, date);
    return new Date(year, month, date) > Date.now();
}

function isValidDOB(x) {
    const dateParts = parseISODateParts(x);
    if (!dateParts) return false;
    const [year, month, date] = dateParts;
    return year > currentYear - 120 && new Date(year, month, date) < Date.now();
}

function parseISODateParts(x) {
    console.log('parseISODate', x);
    var re = /^\d{4}-\d{2}-\d{2}$/;
    if (!re.test(x)) {
        console.log(`it must be YYYY-MM-DD`);
        return false;
    }
    const parts = x.split('-');
    console.log(parts);
    var year = +parts[0];
    const month = +parts[1];
    var date = +parts[2];
    if (month === 2 && date > 29) {
        console.log(`there's max of 29 days in February`);
        return false;
    }
    if (month === 2 && date > 28 && year % 4) {
        console.log(`there's only 28 days in February on a non-leap year`);
        return false;
    }
    if (month === 4 || month === 6 || month === 9 || month === 11) {
        if (date > 30) {
            console.log(`there's only 30 days in this month`);
            return false;
        }
    }
    return [year, month, date];
}

function isValidSsn(x) {
    var digits = x.replace(/-/g, '');
    console.log('SSN', digits);
    return /^\d{9,10}$/.test(digits);
}

function isValidPhone(x) {
    var digits = x.replace(/[-+() .]/g, '');
    console.log('phone', digits);
    return /^\d{10}$/.test(digits);
}

function validate(data, field, name, type, validator) {
    console.log('validate:', field, name, type, validator?'with validator':'required');

    if (validator && !validator(data[field]))
        throw new Error(`Please enter a valid ${name || field}`);

    if (!data[field])
        throw new Error(`Please enter ${name || field}`);

    if (type === 'email') {
        if (!isValidEmail(data[field]))
            throw new Error(`Please enter a valid ${name || field}`);
    }
    if (type === 'phone') {
        if (!isValidPhone(data[field]))
            throw new Error(`Please enter a valid ${name || field}`);
    }
}

export function Edit(props) {
    const type = props.type || "text";
    // date support
    const monthId = props.id + 'month';
    const dateId = props.id + 'date';
    const yearId = props.id + 'year';

    const dateString = props.fields[props.id] || '';
    const dateParts = dateString.split('-');
    const [date, setDate] = useState({
        [yearId]: dateParts[0],
        [monthId]: dateParts[1],
        [dateId]: dateParts[2],
    });

    //useEffect(() => {
    //    //       console.log('useEffect', type);
    //    if (type === 'date') {
    //        console.log('useEffect', type);
    //        //            console.log(date);
    //    }
    //    props.setValue({ target: { id: props.id, value: `${date[yearId]}-${date[monthId]}-${date[dateId]}` } });
    //}, [date, type, monthId, dateId, yearId, props]);

    function onChange(e) {
        console.log(e.target.id, e.target.value);
        const newDate = { ...date, [e.target.id]: e.target.value };
//        console.log(newDate);
        setDate(newDate);
        props.setValue({ target: { id: props.id, value: `${newDate[yearId]}-${newDate[monthId]}-${newDate[dateId]}` } });
    }
//    const minmax = type === 'date' ? { min: '1900-01-01', max: new Date().toISOString().split('T')[0] } : null;
    return (
        <div className="col-md-6 mb-3">
            <label htmlFor={props.id}>{props.name} {props.required &&
                "*"
            }</label>
            <div className="input-group">
                { type === "email" && <div className="input-group-prepend">
                    <span className="input-group-text">@</span>
                </div>
                }
                {type === "date" && <>
                    <select className="form-control" id={monthId}
                        value={date[monthId] || ''} onChange={onChange}>
                        {makeOptions(months)}
                    </select>
                    <select className="form-control" id={dateId}
                        value={date[dateId] || ''} onChange={onChange}>
                        {makeOptions(dates)}
                    </select>
                    <select className="form-control" id={yearId}
                        value={date[yearId] || ''} onChange={onChange}>
                        {makeOptions(yearsPastToFuture(props.allowPastYears,
                            props.allowFutureYears
                        ))}
                    </select>
                </>}
                {type !== "date" && <input type={type} className="form-control" id={props.id}
                    placeholder={props.name}
                    value={props.fields[props.id] || ''}
                    onChange={props.setValue} />
                }
            </div>
        </div>
    );
}
export function Checkbox(props) {
    return <>
        <div className=" col-md-6 mb-3">
            <div className="form-check">
                <input type="checkbox" className="form-check-input" id={props.id}
                    value={props.fields[props.id] || 'x'}
                    onChange={props.setValue} />
                <label className="form-check-label" htmlFor={props.id}>{props.name} {props.required &&
                    "*"
                }</label>
            </div>
        </div>
    </>
}
export function SelectImage(props) {
    function onChange(event) {
        const files = event.target.files;
        if (!files || !files.length) return;
        console.log("got", files.length, "files");
        const file = files[0];
//        if (!file) setError('No file selected');
        setText(`${props.name}: ${file.name}`);
        props.onChange(props.id, file);
    }
    const [text, setText] = useState(`Select ${props.name}`);
    const labelId = `${props.id}Label`
    return (<div className="custom-file">
        <input type="file" id={props.id} name={ props.id}
            onChange={onChange}
            accept="image/*" className="custom-file-input"/>
        <label className="custom-file-label"
            htmlFor={props.id} id={labelId}>{text}</label>
    </div>);
}
export function Select(props) {
    return (
        <div className="col-md-6 mb-3">
            <label htmlFor={props.id}>{props.name} {props.required &&
                "*"
            }</label>
            <select className="custom-select d-block w-100"
                id={props.id}
                value={props.fields[props.id] || ''}
                onChange={props.setValue} >
                
                {!props.defaultValue && <option value="">Please select...</option>}
                {props.children}
            </select>
        </div>
    );
}

export function Prompt(props) {
    const [fields, setValue] = useFormFields({});
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    async function onSave(event) {
        event.preventDefault();
        const code = fields["confirmationCode"];
        if (!code) {
            setError("Confirmation Code is required.");
            return;
        }

        if (code.length !== 8 || !isAlphaNum(code)) {
            setError("Confirmation Code is 8 letters or numbers");
            return;
        }

        try {
            setLoading(true);
            setResult(null);
            setError('');
            const response = await getResult(code);
            console.log("setResult", response);
            setResult(response);
        }
        catch (e) {
//            console.log(e);
            setError(e.message);
        }
        finally {
            setLoading(false);
        }
    }
    return (
        <div>
            <h4>Please, enter your confirmation code</h4>
            <Edit id="confirmationCode" name="Confirmation Code"
                required="true" fields={fields} setValue={setValue} />
            <LoaderButton className="btn btn-primary btn-lg mb-3 btn-block" isLoading={loading}
                onClick={onSave}>View Test Results</LoaderButton>
            {error && <div className="alert alert-danger" role="alert">
                {error}
            </div>}
            {result && !result.found && <div className="alert alert-warning" role="alert">
                Your confirmation code is not valid.
                <hr />
                Please enter a valid confirmation code.
            </div>}
            {result && result.found && !result.ready && <div className="alert alert-primary" role="alert">
                Your confirmation code is valid, but your COVID-19 test result is not ready yet. 
                <hr />
                 Please try again later.
            </div>}
            {result && result.found && result.ready && <div className="alert alert-primary" role="alert">
                Your COVID-19 test
                result is {result.result}.
            </div>}
            {result && result.date && <div className="alert alert-primary" role="alert">
                {result.ready ? "Tested" : "Registered"} on {result.date}.
            </div>}
        </div>
    );
}

function isAlphaNum(str) {
    var code, i, len;

    for (i = 0, len = str.length; i < len; i++) {
        code = str.charCodeAt(i);
        if (!(code > 47 && code < 58) && // numeric (0-9)
            !(code > 64 && code < 91) && // upper alpha (A-Z)
            !(code > 96 && code < 123)) { // lower alpha (a-z)
            return false;
        }
    }
    return true;
};

export function Complete(props) {
    const confirmation = getLocal('confirmation');

    const history = useHistory();
    if (!confirmation.confirmationCode)
        history.push('/');

    const location = getLocal('locationOptions');
    if (location.code !== 'MINI') {
        sessionStorage.clear();
        setLocal('confirmation', confirmation);
    }

    const { labCode, confirmationCode, confirmationSent } = confirmation;

    return (
        <div>
            <h4>Complete</h4>
            <p>Your confirmation code is: <b>{confirmationCode}</b></p>
            <Barcode value={confirmationCode} text={confirmationCode} format="CODE39" />
            {labCode && <>
                <p>Your specimen ID / barcode is: <b>{labCode}</b></p>
                <Barcode value={labCode} text={labCode} format="CODE39"/>
            </>}
            {confirmationSent &&
                <p>We have emailed it to you.</p>
            }
        </div>
    );
}

export function Insured() {
    const history = useHistory();
    const location = getLocal('locationOptions');
    if (!location.code) history.push('/register');

    let state = getLocalInsured();
    if (!state["insRelationship"] && !state["insAddress1"] 
	&& !state["insCity"] && !state["insState"] && !state["insZip"] && !state["insDOB"]) {
        const pat = getLocalPatient();
        state = {
            ...state, insRelationship: "I", insFirstName: pat.firstName, insLastName: pat.lastName,
            insAddress1: pat.address1, //insAddress2: pat.address2,
            insCity: pat.city, insState: pat.state, insZip: pat.zip, 
            insDOB: pat.DOB, insGender: pat.sex
        };
    }
    const [fields, setValue] = useFormFields(state);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    var cardInputs = [
        { id: "insuranceFront", name: "Insurance Card Front", required: location.reqInsuranceCardImage },
        { id: "insuranceBack", name: "Insurance Card Back", required: location.reqInsuranceCardImage },
    ];
    var fileInputs = [...cardInputs,
        { id: "license", name: "Driver's License / Other ID", required: location.reqDriversLicenseImage },
    ];
    const [files, setFiles] = useState({});

    function onFile(id, file) {
        setError('');

        console.log("got", id);
        if (file.size > Constants.maxFileSize)
            setError(`Please pick a file smaller than ${Constants.maxFileSize / 1000000} MB`);
        setFiles({ ...files, [id]: file });
    }
    async function onSave(e) {
        e.preventDefault();
        setError('');
        sessionStorage.setItem("Insured", JSON.stringify(fields));

        // merge into one object
        const data = {
            ...getLocal('location'), ...getLocal('appointment'), ...getLocal('specimen'),
            ...getLocalPatient(), ...getLocal('symptoms'), ...fields,
            location: location.code, // last validated location code 
        };
        if (location.consentPage) {
            data.consentDate = new Date();
        }
        try {
            setLocal('confirmation', {});

            // validate form data
            let card = files["insuranceFront"] && files["insuranceBack"]
            // require either entered fields or ins card image
            if (!card) {
                validate(data, 'insName', 'Insurance');
            }
            if (!card && fields['insName'] === Constants.otherInsurance) {
                validate(data, 'insOther', 'Insurance Carrier Name');
            }
            // if the patient has insurance...
            if (fields['insName'] !== Constants.noInsurance) {
                if (!card) {
                    if (location.reqInsPolicyNumber) validate(data, 'insPolicyNumber', 'Policy Number');
                }
                if (location.reqInsFirstName) validate(data, 'insFirstName', 'Insured First Name');
                if (location.reqInsLastName) validate(data, 'insLastName', 'Insured Last Name');
                if (location.reqInsRelationship) validate(data, 'insRelationship', 'Patient Relationship to Insured');
                if (location.reqInsDOB) validate(data, 'insDOB', "Insured Date of Birth", 'date', isValidDOB);
                if (location.reqInsGender) validate(data, 'insGender', "Insured Gender");
                if (location.reqInsAddress1) validate(data, 'insAddress1', "Insured Address");
                if (location.reqInsCity) validate(data, 'insCity', "Insured City");
                if (location.reqInsState) validate(data, 'insState', "Insured State");
                if (location.reqInsZip) validate(data, 'insZip', "Insured Zip");
            }

            fileInputs.forEach(i => validateFile(i));

            setLoading(true);
            // convert questions to int
            questions.filter(q => data[q.id]).forEach(q => { data[q.id] = parseInt(data[q.id]); })
            symptomIds.filter(x => location[`req${x}`] !== null).forEach(x => { data[x] = data[x] === 'x' ? 3 : 2;})

            if (data['vaccinated']) data['vaccinated'] = parseInt(data['vaccinated']);
            if (data['SymptomDays']) data['SymptomDays'] = parseInt(data['SymptomDays']);

            // post data to the server
            const confirmation = await api.post(data);
            if (!confirmation)
                return;

            //            console.log(fileInputs);
            const filesToUpload = fileInputs.filter(i => files[i.id]);

//            console.log(filesToUpload.length, 'file(s) and a signature to upload...')
            await Promise.all([uploadSignature(confirmation.id),
                ...filesToUpload.map(async i => uploadFile(i, confirmation.id))]);
            console.log('uploaded.');

            confirmation.labCode = data.labCode;
            setLocal('confirmation', confirmation);
            history.push('complete');
        }
        catch (e) {
//            console.log(e);
            setLoading(false);
            setError(e.message);
        }
    }

    return (
        <div>
            <h4>Insurance</h4>
            <p><i>Upload front/back images of insurance card <b>or</b> select insurance name and enter policy number.
            </i></p>

            {location.reqInsuranceCardImage !== null && <div className="form-group">
                <SelectImage name="Insurance Card Front" id="insuranceFront" onChange={onFile} />
                <SelectImage name="Insurance Card Back" id="insuranceBack" onChange={onFile} />
            </div>}
            <div>
                <div className="row">
                    {location.reqInsName !== null &&
                        <Payers locationCode={location.code} 
                        id="insName" name="Insurance"
                        required={location.reqInsName}
                        fields={fields} setValue={setValue} />
                    }
                    {location.reqInsOther !== null && fields.insName === Constants.otherInsurance && <Edit
                        id="insOther" name="Insurance Carrier Name" required={location.reqInsOther} fields={fields} setValue={setValue} />
                    }
                    {location.reqInsPolicyNumber !== null && <Edit id="insPolicyNumber" required={location.reqInsPolicyNumber} name="Policy Number" fields={fields} setValue={setValue} />}

                    {location.reqInsGroupNumber !== null && <Edit id="insGroupNumber" name="Group Number" required={location.reqInsGroupNumber} fields={fields} setValue={setValue} />}
                    {location.reqInsRelationship !== null && <Select id="insRelationship" name="Patient Relationship to Insured" required={location.reqInsRelationship} fields={fields} setValue={setValue}>
                        { makeOptions(Constants.relToInsured.map(rel => ({ id: rel.code, name: rel.name}) )) }
                    </Select>}

                    {fields.insRelationship !== 'I' && <div className="alert alert-info" role="alert">
                        Please update Insured information to reflect the Policy holder/Subscriber.
                    </div>}

                    {location.reqInsFirstName !== null && <Edit id="insFirstName" name="Insured First Name" required={location.reqInsFirstName} fields={fields} setValue={setValue} />}
                    {location.reqInsLastName !== null && <Edit id="insLastName" name="Insured Last Name" required={location.reqInsLastName} fields={fields} setValue={setValue} />}

                    {location.reqInsAddress1 !== null && <Edit id="insAddress1" name="Insured Address" required={location.reqInsAddress1} fields={fields} setValue={setValue} />}

                    {location.reqInsCity !== null && <Edit id="insCity" name="Insured City" required={location.reqInsCity} fields={fields} setValue={setValue} />}
                    {location.reqInsState !== null && <Select id="insState" name="Insured State" required={location.reqInsState} fields={fields} setValue={setValue}>
                        {stateOptions}
                    </Select>}

                    {location.reqInsZip !== null && <Edit id="insZip" name="Insured Zip" required={location.reqInsZip} fields={fields} setValue={setValue} />}

                    {location.reqInsDOB !== null && <Edit id="insDOB" name="Insured Date of Birth" required={location.reqInsDOB} type="date" allowPastYears="120" allowFutureYears="0"
                        fields={fields} setValue={setValue} />}
                    {location.reqInsGender !== null && <Select id="insGender" name="Insured Gender" required={location.reqInsGender} fields={fields} setValue={setValue}>
                        {sexOptions}
                    </Select>}
                </div>
                {location.reqDriversLicenseImage !== null && <div className="form-group">
                    <SelectImage name="Driver's License/Other ID" id="license" onChange={onFile} />
                </div>}
                <div className="form-group">
                {error && <div className="alert alert-danger" role="alert">
                    {error}
                </div>}
                    <LoaderButton className="btn btn-primary btn-lg btn-block"
                        isLoading={loading}
                        onClick={ onSave }>Continue</LoaderButton>
                </div>
            </div>
        </div>
    );
    async function uploadSignature(id) {
        const dataurl = sessionStorage.getItem('symptomSignature');
        if (!dataurl) {
            console.log('no signature');
            return;
        }
        try {
            const res = await fetch(dataurl);
            const mime = res.headers.get("Content-Type");
            //console.log(mime);
            const blob = await res.blob();
            //console.log('create file from a blob...');
            const file = new File([blob], 'name', { type: mime });
            // using arrayBuffer() works too
            //const buffer = await res.arrayBuffer();
            //console.log('create file from a bytes...');
            //const file = new File([buffer], 'required-for-post', { type: mime });
            const response = await postFile(file, 'symptomSignature', id);
            console.log('sig is done.', response);
            return response;
        }
        catch (error) {
            console.error('failed to read or upload the signature');
        }
    }
    async function uploadFile(fileInput, id) {
        console.log('check file', fileInput.id);
        const file = files[fileInput.id];
        if (!file) {
            console.log('no file for', fileInput.id);
            return;
        }
        console.log('upload file', fileInput.id);
        await postFile(file, fileInput.id, id);
    }
    function validateFile(fileInput) {
//        console.log('validateFile', fileInput.id);

        const file = files[fileInput.id];
        if (!file) {
            if (fileInput.required)
                throw new Error(`Please select a file for ${fileInput.name}.`);
            // file is optional
            return;
        }
        if (file.size > Constants.maxFileSize)
            throw new Error(`Please select a smaller file for ${fileInput.name}.`);
    }
}
function Payers({ id, name, required, fields, setValue, locationCode }) {
    const [payers, setPayers] = useState(Constants.insurers);
    React.useEffect(() => {
        console.log('first effect: get payers for location', locationCode);
        api.getPayers(locationCode).then(list => {
            //console.log(list);
            if (!list || !list.length) return;
            setPayers(list);
            //move to a separate effect to avoid dependency on id and fields here
            //if (list.length === 1) fields[id] = list[0];
        });
    }, [locationCode]);
    React.useEffect(() => {
        console.log('second effect');
        if (payers.length === 1) {
            console.log(`set ${id} to ${payers[0]}`);
            fields[id] = payers[0];
        }
    }, [payers, id, fields]);
    return <>
        <Select id={id} name={name} required={required} fields={fields} setValue={setValue}
            defaultValue={payers.length === 1 ? payers[0] : ''}>
            {makeOptions(payers)}
        </Select>
    </>
}

function toPascal(s) {
    if (!s) return '';
    let pascal = s[0].toUpperCase() + s.slice(1);
//    console.log('pascal', s, pascal);
    return pascal;
}
function Consent({ locationCode }) {
    const inputs = [
        {
            id: "accept", type: 'checkbox',
            name: "By checking this box, I agree to the terms and conditions listed above.",
            validator: function (x) { if (x === 'x') return true; throw new Error('Please, review and accept the terms and conditions') },
        },
    ];

    const [consent, setConsent] = useState('Please carefully read the following information... Loading information...');
    React.useEffect(() => {
        api.getConsent(locationCode).then(html => setConsent(html));
    }, [locationCode]);
    return <>
        <div dangerouslySetInnerHTML={{ __html: consent }} />
        <Form formId='consent' inputs={inputs} next="/patient"></Form>
    </>
}
const symptoms = ['Fever', 'Cough', 'Runny Nose', 'Eye Redness', 'Body Aches', 'Fatigue', 'Headache', 'Vomiting', 'Nausea', 'Diarrhea', 'Sore Throat'];
function toId(name) {
    return name.replaceAll(' ', '');
}
const symptomIds = [...symptoms.map(x => toId(x)), "TasteSmell"];

export function Symptoms() {
    const location = getLocal('locationOptions');
    const history = useHistory();
    if (!location.code) history.push('/register');
    const inputs = [
        ...symptoms.map(x => ({
            id: toId(x), type: 'checkbox', name: x,
        })),
        {
            id: "TasteSmell", type: 'checkbox', name: "Loss of Taste or Smell",
        },
        {
            id: "SymptomDays", name: "How many days have you experienced symptoms?",
            options: makeOptions(range(31))
        },
        {
            id: "MedicalHistory", name: "Past Chronic Medical Problems (ex. Hypertension, COPD, Diabetes)",
        },
    ].filter(x => location[`req${x}`] !== null);
    let signature = null;
    function saveSignature() {
        if (!signature) return;
        if (signature.isEmpty()) {
            if (location.reqSymptomSignatureImage) throw new Error('Please, sign in the box to continue');
        }
        else {
            sessionStorage.setItem('symptomSignature', signature.toDataURL());
        }
    }
    return <>
        <Form formId='symptoms' inputs={inputs} saveText='Continue' save={saveSignature} next="/insured"
            title="What symptoms are you experiencing?">
            {location.reqSymptomSignatureImage !== null && <div>
                Please, sign in the box below using your finger or mouse <br/>
                <SignatureCanvas ref={(ref) => {
                    signature = ref; if (!signature) return;
                    console.log('signature')
                    const signed = sessionStorage.getItem('signature');
                    if (signed && signature.isEmpty()) signature.fromDataURL(signed);
                    }} 
                    canvasProps={{ height: 200, className: 'sigCanvas w-100 border border-primary' }} />
                <br />
                <button className="btn btn-danger btn-small btn-block mb-3" onClick={(e) => {
                    e.preventDefault(); signature.clear();
                }}>Clear Signature</button>
            </div>
            }
        </Form>
    </>
}
export default function Patient() {
    const savedState = sessionStorage.getItem("Patient");
    const state = JSON.parse(savedState) || {};
    const [fields, setValue] = useFormFields(state);
    const [error, setError] = useState('');
    const history = useHistory();

    //React.useEffect(() => {
    //    async function setUsb() {
    //        await usb.getDevices(setError);
    //    }
    //    setUsb();
    //}, []);

    const location = getLocal('locationOptions');
    if (!location.code) history.push('/register');
    const consent = location.consentPage ? getLocal('consent') : null;

    const inputs = [
        { id: "firstName", name: "Patient First Name", required: true },
        { id: "lastName", name: "Patient Last Name", required: true },
        { id: "email", name: "Email", type: "email", required: true, },
        { id: "cellPhone", name: "Cell Phone", required: true, type: 'phone' },
        { id: "sex", name: "Gender", options: sexOptions, required: true },
        { id: "DOB", name: "Date of Birth", type: "date", required: true, validator: isValidDOB, allowPastYears: 120, allowFutureYears: 0},
        { id: "address1", name: "Patient Address", required: true },
        { id: "city", name: "Patient City", required: true },
        { id: "state", name: "State", required: true, options: stateOptions },
        { id: "zip", name: "Zip", required: true },
        { id: "county", name: "County", required: false},
        { id: "race", name: "Race", options: raceOptions },
        { id: "ethnicity", name: "Ethnicity", options: ethnicityOptions },
        { id: "driversLicense", name: "Driver's License/Other ID", required: false },
        { id: "SSN", name: "SSN", required: false },
        { id: "employeeID", name: "Employee ID", required: false },
        { id: "affiliation", name: "Are you a", required: true, options: makeOptions(Constants.areyou) },
        { id: "vaccinated", name: "Are you vaccinated", required: true, options: makeOptions(vaccinated) },
        //{ id: "", name: "", required: true, type: "", options: makeOptions()},
    ]
        .filter(i => (i.id !== 'vaccinated' && i.id !== 'affiliation') || location[`use${toPascal(i.id)}`] !== null)
        .filter(i => (i.id === 'vaccinated' || i.id === 'affiliation') || location[`req${toPascal(i.id)}`] !== null)
        .map(i => ({ ...i, required: location[`req${toPascal(i.id)}`] || location[`use${toPascal(i.id)}`] }));

    function onSave() {
        setError('');
        console.log(fields);

        try {
            inputs.filter(i => i.required).forEach(i => { validate(fields, i.id, i.name, i.type, i.validator); });
            questions.filter(i => location[`use${toPascal(i.id)}`]).forEach(i => { validate(fields, i.id, i.name); });

            if (fields.SSN) {
                if (!isValidSsn(fields.SSN))
                    throw new Error(`Please enter a valid SSN`);
            }
            if (fields.DOB) {
                if (!isValidDOB(fields.DOB))
                    throw new Error(`Please enter a valid patient Date of Birth`);
            }

            // save data to the session storage
            sessionStorage.setItem("Patient", JSON.stringify(fields));
            const symptoms = fields.Symptomatic === '3'
                || location.useMedicalHistory !== null || location.reqSymptomSignatureImage;
            
            history.push(symptoms ? "symptoms" : "insured");
        }
        catch (e) {
//            console.log(e);
            setError(e.message);
        }
    }

    function onRadio(e) {
        setValue(e);
    }
    return (
        <div>
            {location.consentPage && (!consent || !consent.accept) && <Consent locationCode={location.code}/>}
            {(!location.consentPage || (consent && consent.accept)) && <>
            <h4 className="mb-3">Please tell us about the patient.</h4>
            <div className="row">
                {inputs.map((input, i) => 
                    input.options ?
                        <Select key={input.id} id={input.id} name={input.name} required={ input.required } fields={fields} setValue={setValue}>
                            {input.options}
                        </Select>
                        :
                        <Edit key={input.id} id={input.id} name={input.name} required={input.required} type={input.type}
                            allowPastYears={input.allowPastYears}
                            allowFutureYears={input.allowFutureYears}
                            fields={fields} setValue={setValue} />
                )}
            </div>
            <h4 className="mb-3">Please answer to the best of your knowledge</h4>
                {questions.filter(i => location[`use${toPascal(i.id)}`] !== null).map(q => (
                    <div key={q.id}>
                        <label>{q.name || `${q.id}?`} {location[`use${toPascal(q.id)}`]?' *' : ''}</label>
                    <br />
                    <div className="btn-group btn-group-toggle" data-toggle="buttons">
                        <label className={"btn btn-outline-primary " + (fields[q.id] === "1" ? "active" : "")}>
                            <input type="radio" name={q.id} onChange={onRadio} value="1" checked={fields[q.id] === "1"} id="option1" /> Unknown
                        </label>
                            <label className={"btn btn-outline-primary " + (fields[q.id] === "2" ? "active" : "")}>
                            <input type="radio" name={q.id} onChange={onRadio} value="2" checked={fields[q.id] === "2"} id="option2"/> No
                        </label>
                            <label className={"btn btn-outline-primary " + (fields[q.id] === "3" ? "active" : "")}>
                            <input type="radio" name={q.id} onChange={onRadio} value="3" checked={fields[q.id] === "3"} id="option3"/> Yes
                        </label>
                    </div>
                    <hr/>
                </div>
            ))}
            {error && <div className="alert alert-danger" role="alert">
                {error}
            </div>}
            <button className="btn btn-primary btn-lg btn-block mb-3" onClick={(e) => { e.preventDefault(); onSave(); }}>Continue</button>
        </>}
        </div>
    );
}

export function Start() {
    const hist = useHistory();
    let initialState = useParams();
    console.log(initialState);
    const location = initialState.location;
    let practice = location === 'at' ? 'ccg' : location === 'ship' ? 'ship' : sessionStorage.getItem('practice');

    // save drop down mode
    if (location === 'at' || location === 'ship') {
        sessionStorage.setItem('practice', practice);
        initialState = null;
    }
    // keep showing drop down if we initially came from practie deep link 
    if (!location) {
        if (practice === 'ccg') {
            hist.push('/register/at');
            return null;
        }
        if (practice === 'ship') {
            hist.push('/register/ship');
            return null;
        }
        initialState = null;
    }
    const options = practice === 'ccg' ? locations : practice === 'ship' ? locationsShip : null;  
    const inputs = [
        {
            id: "location", name: "Location Code", required: true,
            options: options ? makeOptions(options) : null
        },
    ];

    async function onNext(data) {
        const location = await api.getLocation(data.location);
        setLocal('locationOptions', location);
        setLocal('location', { customerId: location.customerId });
        if (!location.selfServe) {
            setLocal('specimen', null);
        }
        hist.push(location.requireAppointment ? '/appointment'
            : location.selfServe ? '/specimen' : "/patient");
    }

    return <Form formId='location' title='Location Code'
        inputs={inputs} save={onNext} initialState={initialState}>
        <div>Location code is provided by testing site.
        </div>
    </Form>
}

async function validateBarcode(code) {
    const location = getLocal('locationOptions');
    console.log('validateBarcode', code, location);
    //const maxCode = location.validateSpecimenId ? 6 : 20;
    const maxCode = 20;
    if (code.length > maxCode || !isAlphaNum(code)) {
        throw new Error(`Lab barcode/specimen ID is up to ${maxCode} letters or numbers`);
    }
    const lab = await api.validateLabCode(code, location.validateSpecimenId, location.code);
    if (!lab.ok) throw new Error('Please see lab technician - you may have to obtain a new barcode/specimen ID');
}
export function Retest() {
    const inputs = [//{ id: "", name: "", required: true, type: "", options: },
        { id: "confirmationCode", name: "Confirmation Code", required: true },
        { id: "labCode", name: "Specimen ID (under the barcode)", required: true },
    ];

    async function onNext(data) {
        const code = data.confirmationCode;
        if (code.length !== 8 || !isAlphaNum(code)) {
            throw new Error('Confirmation Code is 8 letters or numbers');
        }
        var confirmation = await api.retest(data);
        if (!confirmation)
            return;
        confirmation.labCode = data.labCode;
        setLocal('confirmation', confirmation);
    }

    return <Form formId='retest' title='Please enter the confirmation code and the specimen id'
        inputs={inputs} save={onNext} next="/complete"></Form>
}

export function Appointment() {
    const history = useHistory();
    const location = getLocal('locationOptions');
    if (!location.code) history.push('/register');
    const aptDateInput = {
        id: "appointmentDate", name: "Desired Appointment Date (today or later)",
        type: 'date', required: true, validator: isValidAptDate, onChange:loadApt,
        allowPastYears: 0, allowFutureYears: 1
    };

    const [inputs, setInputs] = useState([aptDateInput]);

    async function loadApt(appointmentDate) {
        if (!isValidAptDate(appointmentDate))
            throw new Error("Please submit a valid appointment date to see the available time");
        const apts = await api.getAppointments(location.code, appointmentDate);
        if (!apts.Appointments.length) {
            throw new Error('No appointments are available on this date. Please, select another date.'
            );
        }
        console.log(apts);
        const aptTimeInput = {
            id: "visitDate", name: "Available Appointment Time",
            options: makeOptions(apts.Appointments),
            required: true, //validator: isValidAptDate,
        };
        setInputs([aptDateInput, aptTimeInput]);
    }
    async function onNext(data) {
        const appointmentDate = data.appointmentDate;
        if (!data.visitDate) 
            loadApt(appointmentDate);
        else 
            history.push('/patient');
        //next="/patient"
    }

    return <Form formId='appointment'
        title='Submit the date to see the available time for your appointment'
        inputs={inputs} save={onNext} ></Form>
}


export function Specimen() {
    const inputs = [
        { id: "labCode", name: "Specimen ID (the characters under the barcode)", required: true },
    ];

    async function onNext(data) {
        const code = data.labCode;
        await validateBarcode(code);
    }

    return <Form formId='specimen' title='Please enter the specimen id'
        inputs={inputs} save={onNext} next="/patient"></Form>
}

export function Form({ formId, title, saveText, inputs, save, next, initialState, children }) {
    const savedState = sessionStorage.getItem(formId);
    const state = initialState || JSON.parse(savedState) || {};
    const [fields, setValue] = useFormFields(state);
    const history = useHistory();
    const [error, setError] = useState('');

    if (initialState) {
        submit();
        return null;
    }

    function onChange(inputHandler) {
//        console.log('return onChange');
        return async (e) => {
//            console.log('onChange');
            setValue(e);
            setError('');
            if (inputHandler) {
//                console.log('onChange', e.target.id);
                try {
                    await inputHandler(e.target.value);
                }
                catch (e) {
                    setError(e.message);
                }
            }
        }
    }

    async function onSave(e) {
        e.preventDefault();
        setError('');
        await submit();
    }

    async function submit() {
        try {
            inputs.filter(i => i.required || i.validator)
                .forEach(i => { validate(fields, i.id, i.name, i.type, i.validator); });
            // save data to the session storage
            setLocal(formId, fields);
            if (save) await save(fields);
            if (next) history.push(next);
        }
        catch (e) {
            //            console.log(e);
            setError(e.message);
        }
    }

    return (
        <div>
            <h4 className="mb-3">{title}</h4>
            <div className="row">
                {inputs.map((input, i) =>
                    input.options ?
                        <Select key={input.id} id={input.id} name={input.name} required={input.required}
                            fields={fields} setValue={onChange(input.onChange)}>
                            {input.options}
                        </Select>
                    : input.type === 'checkbox' ?
                        <Checkbox key={input.id} id={input.id} name={input.name}
                            required={input.required}
                            fields={fields} setValue={onChange(input.onChange)} /> 
                    : <Edit key={input.id} id={input.id} name={input.name}
                        required={input.required} type={input.type}
                        allowPastYears={input.allowPastYears} 
                        allowFutureYears={input.allowFutureYears}
                        fields={fields} setValue={onChange(input.onChange)} />
                )}
            </div>
            {children}
            {error && <div className="alert alert-danger" role="alert">
                {error}
            </div>}
            <button className="btn btn-primary btn-lg btn-block mb-3" onClick={onSave}>{saveText || 'Submit'}</button>
        </div>
    );
}

const raceOptions = makeOptions(Constants.races);

const sex = [{ id: "F", name: "Female" }, { id: "M", name: "Male" }, { id: "U", name: "Unknown" },];
const sexOptions = makeOptions(sex);

const ethnicity = [{ id: "H", name: "Hispanic or Latino" },
    { id: "N", name: "Not Hispanic or Latino" }, { id: "U", name: "Unknown" },];
const ethnicityOptions = makeOptions(ethnicity);

const vaccinated = [{
    id: 1, name: 'Unknown'
}, {
        id: 7, name: 'None'
    }, {
    id: 2, name: 'Johnson & Johnson Single Shot'}, {
    id: 3, name: 'Moderna First Shot'}, {
        id: 4, name: 'Moderna Two Shots'
    }, {
        id: 8, name: 'Moderna Three Shots'
    }, {
        id: 5, name: 'Pfizer First Shot'
    }, {
        id: 6, name: 'Pfizer Two Shots'
    }, {
    id: 9, name: 'Pfizer Three Shots'
    },];

function makeOptions(list) {
    return list.map((x, i) =>
        (x.id && <option key={i} value={x.id}>{x.name}</option>)
        || <option key={i} value={x}>{x}</option>
    );
}
const stateOptions = makeOptions(Constants.usStates);

const questions = [//{ id: "", name: "" },
    { id: "FirstTest", name: "First Test?" },
    { id: "EmployedHealthcare", name: "Employed in healthcare?" },
    { id: "Pregnant", name: "" },
    { id: "Congregated", name: "Resident in a congregated care setting (nursing homes, rehabilitation facilities, etc..)?" },
    { id: "Symptomatic", name: "Are you experiencing any symptoms of COVID-19?" },
    { id: "Hospitalized", name: "" },
    { id: "ICU", name: "" },
    { id: "WearMask", name: "If you traveled, did you wear a mask over your nose and mouth?" },
    { id: "AvoidCrowds", name: "If you traveled, did you avoid crowds and stay at least 6 feet/2 meters from anyone who is not traveling with you?" },
    { id: "WashHands", name: "If you traveled, did you wash your hands often or use hand sanitizer (with at least 60% alcohol)?" }, 
];

function range(n) {
//    console.log('range', n);
    return new Array(n).fill().map((x, i) => i);
} 
const dates = range(31).map(i => padLeadingZeros(i + 1, 2));dates.unshift('Date')
//var monthName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const months = range(12).map(i => padLeadingZeros(i + 1, 2)); months.unshift('Month');
const currentYear = new Date().getFullYear();
function yearsPastToFuture(allowPastYears, allowFutureYears) {
    const past = +allowPastYears || 0;
    const future = +allowFutureYears || 0;

    const factor = future > 0 ? 1 : -1; // reverse order for past only years
    const startYear = future > 0 ? currentYear - past : currentYear
    const years = range(past + future + 1)
        .map(i => `${startYear + factor * i}`);
    years.unshift('Year');
    return years;
}
function padLeadingZeros(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
}
const locations = [
    { id: 'BROOK', name: 'BROOKHAVEN' },
    { id: 'CHOAD', name: 'CHOA DULUTH' },
    { id: 'DULUT', name: 'DULUTH (Santa Fe)' },
    { id: 'GAINE', name: 'GAINESVILLE' },
    { id: 'LILBURN', name: 'LILBURN (Plaza)' },
    { id: 'PEACH', name: 'PEACHFORD' },
    { id: 'PEACHTREECORNERS', name: 'PEACHTREE CORNERS(NITROZONE)' },
];
const locationsShip = [
    { id: 'SHIPTOME', name: 'SHIPTOME' },
    { id: 'SHIPTOMEOBS', name: 'SHIPTOMEOBS' },
];

//function listHeader({ columns }) {
function listHeader() {
    return <div className="row">
        <div className="col">Vendor</div>
        <div className="col">Device</div>
        <div className="col">Model</div>
        <div className="col">Open</div>
        <div className="col">ID</div>
    </div>
}
function listItem({ key, device }) {
    return <div key={ key } className="row">
        <div className="col">{device.manufacturerName}</div>
        <div className="col">{device.productName}</div>
        <div className="col">{device.serialNumber}</div>
        <div className="col">{device.opened}</div>
        <div className="col">{device.id}</div>
    </div>
}
export function WebUsb() {
    //React.useEffect(() => {
    //    const title = document.title;
    //    document.title = `${title} | USB Setup`;
    //    return () => { document.title = title; };
    //}, []);
    function got(device) {
        setDevices(devices =>
            (devices.find(d => d.serialNumber === device.serialNumber) ?
                devices : [device, ...devices]))
        // .map(d => d.serialNumber === device.serialNumber ? device : d);
    }
    function del(device) {
        setDevices(devices =>
            devices.filter(d => d.serialNumber !== device.serialNumber));
    }
    async function pairUsb() {
        setLoading(true);
        await usb.pair(got);
        setLoading(false);
    }
    async function connectUsb() {
        setLoading(true);
        await usb.getDevices(got, got, del);
        setLoading(false);
    }
    const [loading, setLoading] = useState(false);
    const [devices, setDevices] = useState([]);

    return <>
        <h1>USB Scanner Setup</h1>
        {devices.length > 0 && listHeader()}
        {devices.length > 0 && devices.map((x, i) => listItem({ key: i, device: x }))}
        {!devices.length && <p>Plug the Card Scanner to the computer USB port,
            then click bellow to connect.</p>}
        {devices.length > 0 && <p>The Card Scanner is connected.</p>}
        <LoaderButton className="btn btn-primary btn-lg btn-block"
            isLoading={loading}
            onClick={pairUsb}>Pair Devices</LoaderButton>
        <LoaderButton className="btn btn-primary btn-lg btn-block"
            isLoading={loading}
            onClick={connectUsb}>Use Paired Devices</LoaderButton>
    </>
}

// SerialPort
export function Usb() {
    function got(device) {
        if (!device) return;
        setDevices(devices =>
            (devices.find(d => d.serialNumber === device.serialNumber) ?
                devices : [device, ...devices]));
    }
    function del(device) {
        if (!device) return;
        setDevices(devices =>
            devices.filter(d => d.serialNumber !== device.serialNumber));
    }
    function gotData(text) {
        if (!text) return;
        setScanned(text);
    }
    async function pairSerial() {
        setLoading(true);
        await serial.pair(got, gotData);
        setLoading(false);
    }
    async function connectUsb() {
        setLoading(true);
        await serial.getPorts(got, got, del, gotData);
        setLoading(false);
    }
    const [loading, setLoading] = useState(false);
    const [devices, setDevices] = useState([]);
    const [scanned, setScanned] = useState('');

    return <>
        <h1>USB Serial Port Scanner Setup</h1>
        {devices.length > 0 && listHeader()}
        {devices.length > 0 && devices.map((x, i) => listItem({ key: i, device: x }))}
        {!devices.length && <p>Plug the Card Scanner to the computer,
            then click bellow to connect.</p>}
        {devices.length > 0 && <p>The device is connected.</p>}
        <LoaderButton className="btn btn-primary btn-lg btn-block"
            isLoading={loading}
            onClick={pairSerial}>Pair Devices</LoaderButton>
        <LoaderButton className="btn btn-primary btn-lg btn-block"
            isLoading={loading}
            onClick={connectUsb}>Use Paired Devices</LoaderButton>
        {scanned && <div> Scanned: {scanned}
        </div>}
    </>
}

